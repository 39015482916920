import { Gender, Sillage } from '~/api/api.declaration';
import { BrandCreateDto } from '~/shared/dto/admin/brand.dto';
import { FeedbackReplyDto } from '~/shared/dto/admin/feedback.dto';
import { FragranceCreateDto, FragranceLinkType, FragranceSetSuperDto } from '~/shared/dto/admin/fragrance.dto';
import { IngredientCreateDto } from '~/shared/dto/admin/ingredient.dto';
import { NoteCreateDto } from '~/shared/dto/admin/note.dto';
import { PerfumerCreateDto } from '~/shared/dto/admin/perfumer.dto';
import { PostCreateDto } from '~/shared/dto/admin/post.dto';
import {
	PushCampaignCreateDto,
	PushExperienceRatingCondition,
	PushUserCondition,
} from '~/shared/dto/admin/push.campaign.dto';
import { SettingsDto, StopWordsDto } from '~/shared/dto/admin/settings.dto';
import { StaffUpdateDto } from '~/shared/dto/admin/staff.dto';
import { UserMessageDto } from '~/shared/dto/admin/user.dto';
import { Concentration, Longevity, Season } from '~/shared/dto/model.enums';
import { Tr } from '.';

export function mapTr<T extends object>(texts: Required<{ [P in keyof T]: string }>) {
	return texts;
}

const en = {
	common: {
		test: 'string',
		param: 'param: {{param}}' as Tr<{ test: string }>,
	},
	errors: {
		ERR_STAFF_NOT_APPROVED: 'Your account is not approved. Please wait for approval.',
	},
	dto: {
		PostCreateDto: {
			...mapTr<PostCreateDto>({
				city: 'City',
				country: 'Country',
				fragranceId: 'Fragrance',
				publishAt: 'Publish At, your local time',
				rating: 'Rating',
				temperature: 'Temperature, degrees',
				text: 'Post text',
				userId: 'Created by',
				weather: 'Weather',
			}),
		},
		StaffUpdateDto: {
			...mapTr<StaffUpdateDto>({
				expiresAt: 'Expire at',
				name: 'Name',
				status: 'Status',
				brandPermission: 'Brand permissions',
				fragrancePermission: 'Fragrance permissions',
				perfumersPermission: 'Perfumers permissions',
				notesPermission: 'Notes & Ingredients permissions',
				staffPermission: 'Staff permissions',
				feedbackPermission: 'Feedback permissions',
				userPermission: 'Users permissions',
				pushPermission: 'Push permissions',
				snapshotPermission: 'Snapshot permissions',
				settingsPermission: 'Settings permissions',
				postPermission: 'SOTD permissions',
			}),
		},
		BrandCreateDto: {
			...mapTr<BrandCreateDto>({
				id: 'ID',
				name: 'Name',
				active: 'Active (visible to customers)',
				country: 'Country',
				description: 'Description',
				synonyms: 'Synonyms',
				images: 'Images',
				comment: 'Put some words about your changes',
				year: 'Year',
			}),
		},
		FragranceSetSuperDto: {
			...mapTr<FragranceSetSuperDto>({
				superFragranceId: 'Super fragrance ID',
			}),
		},
		FragranceCreateDto: {
			...mapTr<FragranceCreateDto>({
				id: 'ID',
				name: 'Name',
				active: 'Active (visible to customers)',
				brandId: 'Brand',
				gender: 'Gender',
				description: 'Description',
				discontinued: 'Discontinued',
				longevity: 'Longevity',
				olfactiveId: 'Olfactive family',
				sillage: 'Sillage',
				years: 'Years',
				brandBaseNotes: 'Brand base notes',
				brandMidNotes: 'Brand mid notes',
				brandTopNotes: 'Brand top notes',
				noseNotes: 'Nose notes',
				perfumers: 'Perfumers',
				comment: 'Put some words about your changes',
				concentration: 'Concentration',
				noseWeather: 'Nose Weather',
				noseOccasion: 'Nose Occasion',
				noseSeason: 'Nose Season',
				noseLongevity: 'Nose Longevity',
				noseSillage: 'Nose Sillage',
				noseConcentration: 'Nose Concentration',
				hiddenPhotos: 'Hidden photos',
				privatePhotos: 'Private photos',
				publicPhotos: 'Public photos',
				links: 'Links',
				ingredients: 'Ingredients',
				preliminaryPhotos: 'Preliminary photos',
				synonyms: 'Synonyms',
			}),
		},
		PerfumerCreateDto: {
			...mapTr<PerfumerCreateDto>({
				id: 'ID',
				active: 'Active (visible to customers)',
				company: 'Company',
				description: 'Description',
				images: 'Images',
				name: 'Name',
				comment: 'Put some words about your changes',
			}),
		},
		NoteCreateDto: {
			...mapTr<NoteCreateDto>({
				id: 'ID',
				active: 'Active (visible to customers)',
				images: 'Images',
				name: 'Name',
				synonyms: 'Synonyms',
				comment: 'Put some words about your changes',
			}),
		},
		FeedbackReplyDto: {
			...mapTr<FeedbackReplyDto>({
				imgLeft: 'Left Image URL (optional)',
				imgRight: 'Right Image URL (optional)',
				description: 'Description (optional)',
				message: 'Message',
				status: 'Status',
				title: 'Title',
				analyticsLabel: 'Analytics label (optional)',
			}),
		},
		UserMessageDto: {
			...mapTr<UserMessageDto>({
				imgLeft: 'Left Image URL (optional)',
				imgRight: 'Right Image URL (optional)',
				description: 'Description (optional)',
				message: 'Message',
				title: 'Title',
				analyticsLabel: 'Analytics label (optional)',
			}),
		},
		PushUserCriteria: {
			...mapTr<PushUserCondition>({
				userIds: 'ID of users to send to',
			}),
		},
		PushExperienceRatingCriteria: {
			...mapTr<PushExperienceRatingCondition>({
				percentOfSetRates: 'Percent of experiences with rates',
				hasNoExperiences: 'Send to users, who has no experiences at all',
			}),
		},
		PushCampaignCreateDto: {
			...mapTr<PushCampaignCreateDto>({
				campaignName: 'Campaign name',
				description: 'Description (optional)',
				imgLeft: 'Left Image URL (optional)',
				imgRight: 'Right Image URL (optional)',
				message: 'Message',
				sendAt: 'Send At (LOCAL user time!)',
				sendNotice: 'Send to Activity',
				sendPush: 'Send push',
				sound: 'Sound',
				title: 'Title',
				vibrate: 'Vibrate',
				analyticsLabel: 'Analytics label',
				audienceConditions: 'Audience criterias',
				badge: 'Badge',
				light: 'Light',
				noticeTTL: 'Notice TTL, seconds',
				pushTTL: 'Push TTL, seconds',
				status: 'Status',
			}),
		},
		IngredientCreateDto: {
			...mapTr<IngredientCreateDto>({
				casNumber: 'CAS number',
				description: 'Description',
				name: 'Name',
				images: 'Images',
				vendor: 'Vendor',
				analogs: 'Analogues',
			}),
		},
		SettingsDto: {
			...mapTr<SettingsDto>({
				fragranceSearchExcludeStrings: 'Exclude strings from fragrance names during search',
				imgBrandBig: 'Big brand image',
				imgBrandSmall: 'Small brand image',
				imgFragrance: 'Fragrance image',
				imgIngredient: 'Ingredient image',
				imgNote: 'Note image',
				imgPerfumer: 'Perfumer image',
				imgUser: 'User image',
			}),
		},
		StopWordsDto: {
			...mapTr<StopWordsDto>({
				reserved: 'Reserved words',
				swear: 'Swear words',
			}),
		},
		Concentration: {
			...mapTr<typeof Concentration>({
				BODY_MIST: 'Body mist',
				EAU_DE_COLOGNE: 'Eau de cologne',
				EAU_DE_PARFUM: 'Eau de parfum',
				EAU_DE_TOILETTE: 'Eau de toilette',
				HAIR_MIST: 'Hair mist',
				OIL_PERFUME: 'Oil perfume',
				OTHER: 'Other',
				PERFUME: 'Perfume',
				SOLID_PERFUME: 'Solid perfume',
			}),
		},
		Longevity: {
			...mapTr<typeof Longevity>({
				ABOUT_10_HOURS: '10 hours',
				ABOUT_1_HOUR: '1 hour',
				ABOUT_5_HOURS: '5 hours',
				LESS_THAN_HOUR: 'Less 1 hour',
				MORE_12_HOURS: 'More 12 hours',
			}),
		},
		Sillage: {
			...mapTr<typeof Sillage>({
				ARM_LENGTH: 'Arm length',
				CLOSE_TO_SKIN: 'Close to skin',
				FEW_METERS: 'Few metres',
				FILLS_ROOM: 'Fills room',
			}),
		},
		Gender: {
			...mapTr<typeof Gender>({
				FEMALE: 'Female',
				MALE: 'Male',
				UNISEX: 'Unisex',
			}),
		},
		Season: {
			...mapTr<typeof Season>({
				AUTUMN: 'Autumn',
				SPRING: 'Spring',
				SUMMER: 'Summer',
				WINTER: 'Winter',
			}),
		},
		FragranceLinkType: {
			...mapTr<typeof FragranceLinkType>({
				ARTICLE: 'Article',
				VIDEO: 'Video',
				INTERNAL: 'Internal',
			}),
		},
	},
};

export default en;
